<template>
  <div>
    <header>
      <h1>{{ $t("HOW-IT-WORK") }}</h1>

      <!-- <div class="input-block">
        <input class="input" placeholder="Chercher ..." />
        <svg
          class="icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24.186"
          height="24.188"
          viewBox="0 0 24.186 24.188"
        >
          <path
            id="Tracé_recherche"
            data-name="Tracé recherche"
            d="M925.289-1399.789h-1.1l-.382-.379a8.948,8.948,0,0,0,2.168-5.844,8.988,8.988,0,0,0-8.989-8.989,8.988,8.988,0,0,0-8.989,8.989,8.988,8.988,0,0,0,8.989,8.988,8.947,8.947,0,0,0,5.843-2.165l.382.379v1.1l6.912,6.9,2.062-2.062-6.9-6.914Zm-8.3,0a6.222,6.222,0,0,1-6.223-6.223,6.223,6.223,0,0,1,6.223-6.223,6.224,6.224,0,0,1,6.223,6.223,6.222,6.222,0,0,1-6.223,6.223Z"
            transform="translate(-908 1415)"
            fill="#757575"
            fill-rule="evenodd"
          />
        </svg>
      </div> -->
    </header>

    <section>
      <nav>
        <!-- <h2>Menu</h2> -->
        <ul>
          <li v-for="menuItem in menuItems" :key="menuItem.label">
            <a
              @click.prevent="toggleSubMenu(menuItem)"
              class="first sub"
              v-if="menuItem.subLinks"
            >
              <svg
                v-if="!menuItem.isOpen"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"
                />
              </svg>
              {{ menuItem.label }}
            </a>
            <router-link :to="menuItem.url" class="first" v-else>
              {{ menuItem.label }}
            </router-link>
            <ul :class="{ open: menuItem.isOpen }">
              <li v-for="subLink in menuItem.subLinks" :key="subLink.label">
                <router-link :to="subLink.url" class="second">
                  {{ subLink.label }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      menuItems: [
        {
          label: this.$t("PERSONAL_INFO"),
          url: "/how-it-work/infoPersonnelle",
        },
        {
          label: this.$t("COMPANY_INFO"),
          url: "/how-it-work/infoEntreprise",
        },
        {
          label: this.$t("TRADUCTION"),
          url: "/how-it-work/traduction",
        },
        {
          label: this.$t("AGENDA"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout agenda",
              url: "/how-it-work/agenda#ajout",
            },
            {
              label: "Modification agenda",
              url: "/how-it-work/agenda#edit",
            },
            {
              label: "Consulter agenda",
              url: "/how-it-work/agenda#delete",
            },
            {
              label: "recherche et filtre",
              url: "/how-it-work/agenda#recherche",
            },
          ],
        },
        {
          label: this.$t("ARTICLE_MAJ"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout article",
              url: "/how-it-work/article#ajout",
            },
            {
              label: "Modification article",
              url: "/how-it-work/article#edit",
            },
            {
              label: "Suppression article",
              url: "/how-it-work/article#delete",
            },
            {
              label: "recherche et filtre",
              url: "/how-it-work/article#recherche",
            },
          ],
        },
        {
          label: "Authentification",
          isOpen: false,
          subLinks: [
            {
              label: this.$t("LOGIN"),
              url: "/how-it-work/authentification#login",
            },
            {
              label: this.$t("LOGOUT"),
              url: "/how-it-work/authentification#deconnection",
            },
            {
              label: this.$t("REGISTRATION"),
              url: "/how-it-work/authentification#inscription",
            },
            {
              label: this.$t("PASSWORD_FORGOT"),
              url: "/how-it-work/authentification#réinitialisation",
            },
          ],
        },
        {
          label: this.$t("Catégories produits"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout categorie",
              url: "/how-it-work/categorie#ajout",
            },
            {
              label: "Modification categorie",
              url: "/how-it-work/categorie#edit",
            },
            {
              label: "Suppression categorie",
              url: "/how-it-work/categorie#delete",
            },
            {
              label: "recherche et filtre",
              url: "/how-it-work/categorie#recherche",
            },
          ],
        },
        {
          label: this.$t("CUSTOMER"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout client",
              url: "/how-it-work/client#ajout",
            },
            {
              label: "Modification client",
              url: "/how-it-work/client#edit",
            },
            {
              label: "Suppression client",
              url: "/how-it-work/client#delete",
            },
            {
              label: "recherche et filtre",
              url: "/how-it-work/client#recherche",
            },
          ],
        },
        {
          label: this.$t("DELEGATAIRE"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout delegataire",
              url: "/how-it-work/delegataire#ajout",
            },
            {
              label: "Modification delegataire",
              url: "/how-it-work/delegataire#edit",
            },
            {
              label: "Consulter delegataire",
              url: "/how-it-work/delegataire#delete",
            },
          ],
        },
        {
          label: this.$t("ESTIMATE"),
          isOpen: false,
          subLinks: [
            {
              label: "Consulter devis",
              url: "/how-it-work/devis#consulter",
            },
            {
              label: "Modification devis",
              url: "/how-it-work/devis#edit",
            },
            {
              label: "Envoyer devis",
              url: "/how-it-work/devis#send",
            },
            {
              label: "Telecharger devis",
              url: "/how-it-work/devis#download",
            },
            {
              label: "Signature devis",
              url: "/how-it-work/devis#signature",
            },
          ],
        },
        {
          label: this.$t("INVOICE"),
          isOpen: false,
          subLinks: [
            {
              label: "Creation Facture manuelle",
              url: "/how-it-work/facture#manuelle",
            },
            {
              label: "Générer Facture depuis devis",
              url: "/how-it-work/facture#devis",
            },
            {
              label: "Générer Facture acompte depuis devis",
              url: "/how-it-work/facture#acompte",
            },
            {
              label: "Consultation des factures",
              url: "/how-it-work/facture#consultation_facture",
            },
            {
              label: "Consultation des factures acomptes",
              url: "/how-it-work/facture#consultation_acompte",
            },
          ],
        },
        {
          label: this.$t("TypePrestation.Forfait"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout forfait",
              url: "/how-it-work/forfait#ajout",
            },
            {
              label: "Modification forfait",
              url: "/how-it-work/forfait#edit",
            },
            {
              label: "Suppression forfait",
              url: "/how-it-work/forfait#delete",
            },
            {
              label: "recherche et filtre",
              url: "/how-it-work/forfait#recherche",
            },
          ],
        },
        {
          label: this.$t("TypePrestation.MainOeuvre"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout mainOeuvre",
              url: "/how-it-work/mainOeuvre#ajout",
            },
            {
              label: "Modification mainOeuvre",
              url: "/how-it-work/mainOeuvre#edit",
            },
            {
              label: "Suppression mainOeuvre",
              url: "/how-it-work/mainOeuvre#delete",
            },
            {
              label: "recherche et filtre",
              url: "/how-it-work/mainOeuvre#recherche",
            },
          ],
        },
        {
          label: this.$t("SOUS-TRAITANT"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout sousTraitant",
              url: "/how-it-work/sousTraitant#ajout",
            },
            {
              label: "Modification sousTraitant",
              url: "/how-it-work/sousTraitant#edit",
            },
            {
              label: "Consulter sousTraitant",
              url: "/how-it-work/sousTraitant#delete",
            },
          ],
        },
        {
          label: this.$t("PIECE"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout piece",
              url: "/how-it-work/piece#ajout",
            },
            {
              label: "Modification piece",
              url: "/how-it-work/piece#edit",
            },
            {
              label: "Suppression piece",
              url: "/how-it-work/piece#delete",
            },
            {
              label: "recherche et filtre",
              url: "/how-it-work/piece#recherche",
            },
          ],
        },
        {
          label: this.$t("WORKS"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout travaux",
              url: "/how-it-work/travaux#ajout",
            },
            {
              label: "Modification travaux",
              url: "/how-it-work/travaux#edit",
            },
            {
              label: "Suppression travaux",
              url: "/how-it-work/travaux#delete",
            },
            {
              label: "recherche et filtre",
              url: "/how-it-work/travaux#recherche",
            },
          ],
        },
        {
          label: this.$t("USER"),
          isOpen: false,
          subLinks: [
            {
              label: "Ajout utilisateur",
              url: "/how-it-work/utilisateur#ajout",
            },
            {
              label: "Modification utilisateur",
              url: "/how-it-work/utilisateur#edit",
            },
            {
              label: "Suppression utilisateur",
              url: "/how-it-work/utilisateur#delete",
            },
            {
              label: "recherche et filtre",
              url: "/how-it-work/utilisateur#recherche",
            },
          ],
        },
      ],
    };
  },

  computed: {
    ...mapGetters([""]),
  },

  methods: {
    ...mapActions([]),
    toggleSubMenu(menuItem) {
      menuItem.isOpen = !menuItem.isOpen;
    },
  },

  watch: {
    "$route.hash": {
      handler(newHash) {
        if (newHash) {
          this.$nextTick(() => {
            const video = document.getElementById(newHash.slice(1));
            if (video) {
              window.scrollTo({
                top: video.offsetTop,
                behavior: "smooth",
              });
            }
          });
        }
      },
      immediate: true,
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
header {
  @media only screen and (max-width: 900px) {
    display: block;
  }
  background-color: #f5f6f8;
  padding: 72px 24px 14px;
  box-shadow: 2px 0 2px rgba($color: #000, $alpha: 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 16px;
    color: #242424;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1;
  }

  .input-block {
    @media only screen and (max-width: 900px) {
      margin-top: 8px;
    }
    position: relative;

    .icon {
      position: absolute;
      left: 14px;
      bottom: 0;
      top: 0;
      width: 18px;
      height: 18px;
      margin: auto 0;
    }

    input {
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
      font-size: 14px;
      border-radius: 5px;
      padding: 8px 12px;
      padding-left: 36px;
      background-color: #fff;
      border: 1px solid #f2f2f2;
      outline: none;

      &::placeholder {
        color: #393939;
        font-size: 12px;
      }
    }
  }
}

section {
  background-color: #fff;
  display: flex;
  nav {
    @media only screen and (max-width: 900px) {
      width: 25%;
      padding-left: 20px;
    }
    @media only screen and (max-width: 500px) {
      display: none;
    }
    padding-top: 24px;
    padding-left: 32px;
    width: 20%;
    max-width: 320px;
    border-right: 1px solid #e2e2e2;
    min-height: calc(100vh - 125px);
    // height: calc(100vh - 125px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #b5b5b5;
      box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
    }

    h2 {
      @media only screen and (max-width: 900px) {
        font-size: 14px;
      }
      color: #242424;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    ul {
      & ul {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
      }

      & .open {
        max-height: 500px;
        transition: max-height 0.3s ease-in;
      }
    }
    ul {
      list-style-type: none;
    }

    .first {
      @media only screen and (max-width: 900px) {
        font-size: 12px;
      }
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 8px;
      text-decoration: none;
      color: #474747;
      font-size: 14px;
    }
    .second {
      @media only screen and (max-width: 900px) {
        font-size: 12px;
      }
      display: block;
      color: #28367a;
      text-decoration: underline;
      font-size: 14px;
      margin-bottom: 8px;
    }

    .sub {
      @media only screen and (max-width: 900px) {
        margin-left: -18px;
      }
      margin-left: -24px;

      svg {
        fill: #474747;
      }
    }
  }
}
</style>
